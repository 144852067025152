import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { reduxStore } from './redux/Reducers';
import { ThemeProvider } from '@material-ui/styles';
import { ThemeProvider as ThemeProviderJSS } from 'react-jss';
import { theme } from './styling/DefaultTheme';
import { aroma } from '@design-system/themes/aroma';
import BourdainStyleProvider from './styling/BourdainStyleProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import LoadingIndicator from './shared/components/static/LoadingIndicator';

// Initialize Firebase
import initializeFirebaseApp from './FirebaseConfig';

// Google tag manager
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

const dataLayer: any = window.dataLayer || [];
window.dataLayer = dataLayer;

const analytics = Analytics({
  app: 'bourdain',
  plugins: [
    googleTagManager({
      containerId: 'G-HDB70CLQ8P',
    }),
  ],
});

analytics.page();

const store = createStore(reduxStore, applyMiddleware(thunk));
const persistor = persistStore(store);
initializeFirebaseApp(store);

function generateNonce(length = 16) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}

const nonce = generateNonce();

// Inject CSP meta tag directly into the document head
const cspMetaTag = document.getElementById('meta-csp');
cspMetaTag.httpEquiv = 'Content-Security-Policy';
cspMetaTag.content = `
                    default-src 'self';
                    font-src
                      'self'
                      https://fonts.gstatic.com
                      https://fonts.googleapis.com;
                    script-src
                      'self'
                      'unsafe-inline'
                      https://cdn.firebase.com
                      https://*.firebaseio.com
                      https://apis.google.com
                      https://accounts.google.com/gsi/client
                      https://googletagmanager.com
                      https://*.googletagmanager.com
                      https://*.google-analytics.com;
                    img-src
                      'self'
                      https://firebasestorage.googleapis.com
                      https://*.google-analytics.com;
                    child-src 'self' https://*.firebaseapp.com https://*.googleusercontent.com;
                    style-src 'self' 'unsafe-inline' ${nonce} https://fonts.googleapis.com;
                    frame-src 'self' https://*.firebaseapp.com https://*.google.com https://*.googleapis.com https://accounts.google.com/gsi/;
                    connect-src
                      'self'
                      https://accounts.google.com/gsi/
                      https://*.googleapis.com
                      https://*.google-analytics.com;
`;
const secondCspMetaTag = document.createElement('meta');

secondCspMetaTag.setAttribute('property', 'csp-nonce');
secondCspMetaTag.setAttribute('content', nonce);

document.head.appendChild(secondCspMetaTag);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ThemeProviderJSS theme={aroma} nonce={nonce}>
            <BourdainStyleProvider>
              <App nonce={nonce}/>
            </BourdainStyleProvider>
          </ThemeProviderJSS>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('react-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Service Worker
serviceWorker.unregister();
